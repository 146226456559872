<template>
    <ProductCardContent v-editable="blok" :data="productCardContentData" />
</template>

<script setup lang="ts">
import ButtonType from '~/types/ButtonType';
import ProductCardContentType from '~/types/ProductCardContentType';
import ImageType from '~/types/ImageType';
import LinkType from '~/types/LinkType';
import ProductCardType from '~/types/ProductCardType';
import ProductCardContent from '~/components/page-building/ProductCardContent.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const productCardContentData = computed<ProductCardContentType>(() => {
    const buttons = props.blok.buttons
        ? props.blok.buttons.map((button: any) => {
              return {
                  label: button.label,
                  style: button.style,
                  url: button.link.cached_url,
                  vEditable: button,
              } as ButtonType;
          })
        : [];

    const cards = props.blok.cards
        ? props.blok.cards.map((card: any) => {
              return {
                  image: {
                      title: card.image.title,
                      alt: card.image.alt,
                      src: card.image.filename,
                  } as ImageType,
                  icon: card.icon
                      ? ({
                            title: card.icon.title,
                            alt: card.icon.alt,
                            src: card.icon.filename,
                        } as ImageType)
                      : null,
                  category: card.category,
                  title: card.title,
                  description: card.description,
                  link:
                      card.link.length > 0
                          ? ({
                                label: card.link[0].label,
                                url: card.link[0].target.cached_url,
                                target: '',
                            } as LinkType)
                          : null,
                  vEditable: card,
              } as ProductCardType;
          })
        : [];

    return {
        buttons,
        cards,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});
</script>
