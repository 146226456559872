<template>
    <div
        class="flex h-full w-full rounded-xl bg-white text-grind drop-shadow-[0_0_8px_rgba(20,20,20,0.1)]"
        :class="mainClasses"
    >
        <div class="relative bg-gray-200" :class="imageContainerClasses">
            <StoryblokImage
                v-if="card.image"
                :src="card.image.src"
                :alt="card.image.alt"
                class="h-full w-full object-cover"
                :class="imageClasses"
                itemprop="image"
            />
            <img
                v-if="card.icon"
                :src="card.icon.src"
                :alt="card.icon.alt"
                class="absolute left-[10px] top-[10px] h-[60px] w-[60px]"
            />
        </div>
        <div
            class="flex flex-1 flex-col justify-between"
            :class="contentClasses"
        >
            <div class="flex flex-col">
                <TagGroup
                    :tags="[card.category]"
                    background="white"
                    class="mb-2 flex-wrap"
                />
                <div class="mb-2 text-xl font-bold" itemprop="headline">
                    {{ card.title }}
                </div>
                {{ card.description }}
            </div>
            <div class="mt-8">
                <a :href="card.link.url">{{ card.link.label }}</a>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ArticleCardType from '~/types/ArticleCardType';

export interface Props {
    card: ArticleCardType;
    size?: string;
}
const props = withDefaults(defineProps<Props>(), {
    size: 'small',
});

const isSmall = computed<boolean>(() => {
    return props.size === 'small';
});

const mainClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'flex-col';
    }
    return '';
});
const imageContainerClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'rounded-t-[10px] h-[235px]';
    }
    return 'rounded-bl-[10px] rounded-tl-[10px] w-1/3';
});
const imageClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'rounded-t-[10px]';
    }
    return 'rounded-bl-[10px] rounded-tl-[10px]';
});
const contentClasses = computed<string>(() => {
    if (isSmall.value) {
        return 'p-4';
    }
    return 'px-4 py-12';
});
</script>
